.logo {
    z-index: 999!important;
    width: 250px;
    object-fit: contain;
}
@media (min-width:1000px) and (max-width:1400px) {
    .second-bacground span {
        padding: 10px 20px;
    }
    .header-button-active{
        font-size: 15px;
    }
    .header-button {
        font-size: 15px;
    }
    .header-button:hover {
        font-size: 15px;
    }
    .header-left-content {
        gap: 0px 10px;
    }
    .header-left-bamboo {
        width: 50px;
        height: 50px;
    }
    .header-right-planet {
        width: 475px;
        height: 400px;
    }
    .first-content .col-8 {
        width: 80%;
    }
    .first-content .col-4 {
        width: 20%;
    }
    .first-content .rock {
        left: -85%;
        bottom: -40%;
        width: 35%;
    }
    .first-content .right-bambu {
        width: 40%;
    }
    .left-img {
        width: 25%;
        bottom: 0%;
    }
    .second-bacground .col-md-8 {
        width: 80%;
    }
    .second-bacground .col-md-4 {
        width: 20%;
    }
    .second-bacground .rock-right {
        top: -8%;
        right: 4%;
    }
    .second-bacground .bambu-right-bottom {
        width: 25%;
        bottom: 40%;
    }
    .second-bacground .contact-area {
        padding: 20px 50px;
    }
    .footer .col-md-6 {
        width: 75%;
    }
    .logo {
        width: 230px;
        object-fit: contain;
    }
    .first-content p {
        font-size: 12px;
    }
    .gost .col-8 {
        width: 80%;
    }
    .gost .col-4 {
        width: 20%;
    }
    .gost h2 {
        font-size: 34px;
    }
    .first-content span {
        font-size: 13px;
    }
}
@media (min-width:1200px) and (max-width:1367px) {
    .footer .col-md-6 {
        width: 80%;
    }
    .first-content span {
        font-size: 14px;
    }
}

@media (min-width:1000px) and (max-width:1200px) {
    @media (min-height:600px) and (max-height:700px) {
        .first-content .col-8 {
            width: 95%;
        }
        .first-content .col-4 {
            width: 5%;
        }
        .gost .col-8 {
            width: 95%;
        }
        .gost .col-4 {
            width: 5%;
        }
        .second-bacground .col-md-8 {
            width: 95%;
        }
        .second-bacground .col-md-4 {
            width: 5%;
        }
        .gost h2 {
            font-size: 34px;
        }
        .footer .col-md-6 {
            width: 90%;
        }
    }
}

@media (min-width:768px) and (max-width:1000px) {
    .second-bacground .rock-right {
        top: -3%;
    }
    .contact-area {
        padding: 20px 120px;
    }
    .gost-section h3 {
        font-size: 18px;
    }
    .webView {
        display: none !important;
    }
    .mobileView {
        display: none !important;
    }
    .tabletView {
        display: flex !important;
    }
    .header-right-planet {
        width: 300px;
        height: 250px;
    }
    .logo {
        width: 220px;
    }
    .social-rock {
        width: 190px;
    }
    .social-media a {
        margin-right: 15px;
    }
    .first-content .col-8 {
        width: 95%;
    }
    .first-content .col-4 {
        width: 5%;
    }
    .gost .col-8 {
        width: 95%;
    }
    .gost .col-4 {
        width: 5%;
    }
    .gost-section h2 {
        font-size: 28px;
        color: #ffffff;
        font-weight: 600;
    }
    .first-content span {
        padding: 10px 5px;
    }
    .first-content h1 {
        font-size: 28px;
    }
    .first-content h1 a {
        font-size: 28px;
    }
    .first-content .right-bambu {
        width: 150px;
        height: 200px;
    }
    .second-bacground .col-md-8 {
        width: 95%;
    }
    .second-bacground .col-md-4 {
        width: 5%;
    }
    .second-bacground h1 {
        font-size: 28px;
    }
    .second-bacground h1 span {
        font-size: 28px;
    }
    .second-bacground p {
        font-size: 12px;
    }
    .second-bacground .contact-area {
        z-index: 2;
        padding: 20px 40px;
    }
    .second-bacground span {
        padding: 10px 25px;
    }
    .right-content {
        z-index: 2;
    }
    .header-menu {
        border-radius: 20px;
        background-color: #2A0865;
        padding: 20px 30px;
        position: absolute;
        display: flex;
        flex-direction: column;
        text-align: center;
        gap: 20px 0px;
    }
    .header-menu-close {
        display: none;
    }
    .header-menu  a {
        color: #ffffff !important;
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;
    }
}

@media (max-width:767px) {
    .download-button {
        align-items: center;
        justify-content: center;
     }
    .pink-button {
        text-align: center;
    }
    .purpule-button {
        text-align: center;
    }
    .select-input {
        height: 50px;
    }
    .webView {
        display: none !important;
    }
    .mobileView {
        display: flex !important;
    }
    .contact-area {
        padding: 20px 10px;
    }
    .second-bacground .contact-area {
        padding: 20px 10px;
    }
    .header-right-planet {
        width: 235px;
        height: 200px;
    }
    .first-content .col-8 {
        width: 100%;
    }
    .logo {
        width: 180px;
        position: relative;
    }
    .social-rock {
        width: 100px;
    }
    .social-media {
        display: flex;
        left: 55%;
        justify-content: center;
    align-items: center;
    }
    .social-media img {
        width: 15px;
        object-fit: contain;
    }
    .social-media a {
        margin-right: 10px;
    }
    .header-left-bamboo {
        width: 100px;
        height: 100px;
    }
    .first-content span {
        padding: 10px;
        text-align: center;
    }
    .first-content h1 {
        text-align: center;
        font-size: 22px;
    }
    .first-content h1 a {
        font-size: 22px;
    }
    .first-content p {
        text-align: center;
    }
    .first-content .right-bambu {
        width: 100px;
        height: 150px;
    }
    .first-content .button-area {
        gap: 0px;
        justify-content: space-evenly;
        width: 100%;
    }
    .mobile-margin {
        margin-top: 100px;
    }
    .second-content p {
        font-size: 16px;
    }
    .second-bacground span {
        padding: 10px 30px;
        text-align: center;
    }
    .second-bacground h1 {
        text-align: center;
    }
    .second-bacground h1 span {
        font-size: 27px;
    }
    .second-bacground p {
        font-size: 16px;
        text-align: center;
    }
    .second-bacground h2 {
        text-align: center;
        margin-bottom: 20px;
    }
    .second-bacground .bambu-right-bottom {
        width: 200px;
        height: 200px;
        bottom: 35%;
    }
    .second-bacground .contact-area h2 {
        font-size: 24px;
        margin-bottom: 0px;
    }
    .second-bacground .contact-area p {
        font-size: 16px;
        margin-bottom: 0px;
    }
    .mobile-menu {
        display: flex;
        position: relative;
        flex-direction: row;
        gap: 0px 20px;
        z-index: 11;
        margin-top: 50px;
    }
    .mobile-menu a {
        color: #ffffff !important;
        font-size: 14px;
        font-weight: 400;
    }
    #root {
        overflow-x: hidden !important;
    }
    .second-content h1 {
        font-size: 35px;
    }
    .web-margin {
        margin-top: 200px;
    }
    .right-content {
        z-index: 2;
    }
    .header-menu {
        position: fixed;
        height: 100vh;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #2A0865;
        padding: 20px 30px;
        gap: 20px 0px;
        z-index: 10;
        top: 0;
        left: 0;
        flex-direction: column;
    }
    .header-menu-close {
        display: none;
    }
    .header-menu  a {
        color: #ffffff !important;
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;
    }
    .gost .col-8 {
        width: 100%;
    }
    .gost .col-4 {
        width: 0%;
    }
    .gost h2 {
        font-size: 28px;
    }
    .gost p {
        text-align: center;
        font-size: 16px;
    }
    .gost .gost-mobile {
        flex-direction: column !important;
        justify-content: center;
        align-items: center;
    }
    .second-bacground .rock-right {
        top: -2%;
    }
    .swiper-img {
        width: 225px;
    }
    .map-text {
        text-align: center;
    }
    .map-title {
        text-align: center;
    }
}