.footer-background{
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
}
.left-content {
    display: flex;
    align-items: flex-start !important;
    justify-content: flex-start;
    gap: 20px 0px;
    flex-direction: column;
    z-index: 2;
    position: relative;
}
.left-content p {
    color: #ffffff;
    font-weight: 400;
    font-size: 10px;
}
.left-content img {
    padding: 10px;
    border-radius: 5px;
    background-color: #fa00ff;
    margin-right: 20px;
}
.left-content a {
    color: #ffffff !important;
    font-size: 16px;
    font-weight: 500;
}
.right-content {
    display: flex;
    gap: 20px 0px;
    align-items: flex-end;
    flex-direction: column;
}
.right-content a {
    color: #ffffff !important;
    font-size: 16px;
    font-weight: 500;
}