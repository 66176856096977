.header-left-content{
    display: flex;
    justify-content: flex-end;
    gap: 0px 20px;
    align-items: center;
}
.header-button-active{
    background-color: #FA00FF;
    color: #ffffff;
    font-size: 16px;
    padding: 10px 15px;
    font-weight: 600;
    font-family: 'Chakra Petch';
    text-decoration: none;
    border-radius: 20px;

}
.header-button {
    color: #ffffff;
    font-size: 16px;
    padding: 10px 15px;
    font-weight: 600;
    font-family: 'Chakra Petch';
    text-decoration: none;
}

.header-button:hover {
    background-color: #FA00FF;
    color: #ffffff;
    font-size: 16px;
    padding: 10px 15px;
    font-weight: 600;
    font-family: 'Chakra Petch';
    text-decoration: none;
    border-radius: 20px;
}

.social-media {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.social-media a {
    margin-right: 20px;
}
.header-left-bamboo {
    position: absolute;
    left: 0;
    top: 0;
    width: 200px;
    height: 200px;
}
.header-right-planet {
    position: absolute;
    right: 0;
    top: 0;
    width: 950px;
    height: 800px;
}
.sticky-header {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgb(20,3,40,0.9);
    transition: linear 0.5s;
    z-index: 11;
}
.header-menu img {
    padding: 10px;
    border-radius: 5px;
    background-color: #fa00ff;
    margin-right: 20px;
}
.header-languege-active {
    padding: 10px;
    background-color: #FA00FF;
    color: #ffffff;
    border-radius: 10px;
}