.webView {
    display: flex !important;
}
.mobileView {
    display: none;
}
.tabletView {
    display: none;
}
#root {
    background-color: #1A0B3F;
    
}
a {
    text-decoration: none;
    cursor: pointer;
}
/* First Content */
.first-background {
    background: url(../assets/images/first-section.png) no-repeat;
    height: auto;
}
.first-content span {
    background-color: #2a0865;
    border-radius: 20px;
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Chakra Petch';
    width: fit-content;
    padding: 10px 15px;
    display: flex;
}
.first-content span img {
    margin-left: 10px;
    width: 16px;
    object-fit: contain;
}
.first-content h1 a {
    background-color: transparent;
    color: #fa00ff !important;
    font-size: 40px;
    font-weight: 400;
    font-family: 'Chakra Petch';
    padding: 0px;
    width: fit-content;
}
.pink-button:hover {
    opacity: 0.8;
}
.purpule-button:hover {
    opacity: 0.8;
}
.first-content h1 {
    color: #ffffff;
    font-size: 40px;
    font-weight: 400;
    font-family: 'Chakra Petch';
}
.first-content p {
    font-size: 16px;
    color: #ffffff;
    font-weight: 500;
}
.first-content .button-area {
    display: flex;
    gap: 0px 50px;
}
.pink-button {
    background-color: #fa00ff;
    border-radius: 20px;
    color: #ffffff;
    position: relative;
    z-index: 2;
    padding: 8px 5px 8px 20px;
    font-size: 16px;
    text-decoration: none;
    font-weight: 600;
    display: flex;
    align-items: center;
    cursor: pointer;
    border: none;
}
.first-content .pink-button {
    background-color: #fa00ff;
    border-radius: 20px;
    color: #ffffff;
    position: relative;
    z-index: 2;
    padding: 8px 5px 8px 20px;
    font-size: 16px;
    text-decoration: none;
    font-weight: 600;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.pink-button img{
    padding: 8px 10px;
    border-radius: 100%;
    background-color: #D400D9;
    margin-left: 10px;
}
.first-content .pink-button img{
    padding: 8px 10px;
    border-radius: 100%;
    background-color: #D400D9;
    margin-left: 10px;
}
.first-content .purpule-button {
    background-color: #1A0B3F;
    border-radius: 20px;
    color: #ffffff;
    position: relative;
    z-index: 2;
    padding: 8px 5px 8px 20px;
    font-size: 16px;
    text-decoration: none;
    font-weight: 600;
    border: 1px solid #2A0865;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.game-banner{
    width: 100px!important;
    border-radius: 10px!important;
    border:0.5px solid #fff!important;
}
.interface {
    width: 100%;
    border-radius: 30px;
    border:1px solid #fff;


}
.game-banner{
    width: 50%;
    border-radius: 30px;
    border:1px solid #fff;
}
.why-area{
    color: #fff;
    text-align: center;
    
}
.langButton{
    cursor: pointer;

}
.why-area li{
    color: #fff;
    text-align: start;
    line-height: 2;
}
.first-content .purpule-button img{
    padding: 8px 10px;
    border-radius: 100%;
    background-color: #2A0865;
    margin-left: 10px;
}
.first-content .right-bambu {
    position: absolute;
    right: 0;
    bottom: -20%;
    max-width: 30%;
}
.first-content .rock {
    position: absolute;
    left: -80%;
    bottom: -40%;
    max-width: 100%;
}
.contact-area {
    background-color: #2A0865;
    padding: 20px 180px;
    width: fit-content;
    border-radius: 40px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px 0px;
}
.contact-area h2 {
   color: #ffffff;
   font-size: 30px;
   font-weight: 600; 
   text-align: center;
   font-family: 'Chakra Petch';
}
.contact-area p {
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
}

.game-desc-area {
    text-align: start!important;
    background-color: #2A0865;
    padding: 50px;
    border-radius: 30px;
    width: 80%;
  }
  .game-desc-area li{
    line-height: 2;
}
.game-desc-area strong{
  color: #D400D9!important;
}

.game-desc-area h4{
    color: #4eed0a!important;
  }
  .game-video{
    width: 60%;
    border-radius: 30px;
    position: relative;
  }

/* Second Content */

.second-content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px 0px;
    flex-direction: column;
    text-align: center;
}

.second-content span {
    background-color: #2A0865;
    border-radius: 20px;
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Chakra Petch';
    display: flex;
    align-items: center;
    gap: 0px 10px;
    padding: 10px 42px;
    width: fit-content;
}
.second-content h1 {
    color: #ffffff;
    font-size: 42px;
    font-weight: 600;
    font-family: 'Chakra Petch';
}
.second-content img {
    max-width: 100%;
}
.second-content p {
    color: #ffffff;
    font-weight: 500;
    font-size: 16px;
}
.second-bacground {
    background-image: url(../assets/images/second-section.png);
    height: auto;
}
.left-img {
    position: absolute;
    left: 0;
    max-width: 100%;
}
.second-bacground span {
    background-color: #2A0865;
    border-radius: 20px;
    padding: 10px 60px;
    display: flex;
    align-items: center;
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    width: fit-content;
    gap: 0px 20px;
}
.second-bacground h1 {
    color: #ffffff;
    text-align: end;
    font-family: 'Chakra Petch';
}
.second-bacground h1 span {
    color: #fa00ff;
    background-color: transparent;
    font-size: 42px;
    font-weight: 400;
    padding: 0px;
    display: contents;
}
.second-bacground p {
    text-align: end;
    color: #ffffff;
    font-size: 15px;
    font-weight: 400;
}
.second-bacground h2 {
    color: #ffffff;
    font-size: 20px;
    font-weight: 600;
    text-align: end;
    font-family: 'Chakra Petch';
}
.second-bacground .pink-button {
    background-color: #fa00ff;
    border-radius: 20px;
    color: #ffffff;
    position: relative;
    padding: 8px 5px 8px 20px;
    font-size: 16px;
    text-decoration: none;
    font-weight: 600;
    display: flex;
    align-items: center;
    cursor: pointer;
    width: max-content;
}
.second-bacground .pink-button img{
    padding: 8px 10px;
    border-radius: 100%;
    background-color: #D400D9;
    margin-left: 10px;
}
.second-bacground .rock-right {
    position: absolute;
    right: 13%;
    top: -10%;
    max-width: 100%;
}
.second-bacground .bambu-right-bottom {
    position: absolute;
    right: 0;
    bottom: 30%;
}
.second-bacground .contact-area {
    background: transparent;
    padding: 20px 180px;
    width: fit-content;
    border-radius: 40px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px 0px;
}
.second-bacground .contact-area h2 {
   color: #ffffff;
   font-size: 30px;
   font-weight: 600; 
   text-align: center;
   font-family: 'Chakra Petch';
}
.second-bacground .contact-area p {
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
}
.header-menu-close {
    display: none;
}
.up-arrow {
    position: fixed;
    bottom: 5%;
    right: 5%;
    z-index: 15;
}
.goster {
    display: none;
}
.whatsapp {
    position: fixed;
    bottom: 5%;
    left: 5%;
    z-index: 10;
    font-size: 12px;
    font-weight: 600;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    cursor: pointer;
}

.whatsapp:hover{
    animation: shake 1s;
    animation-iteration-count: infinite;
}
.little-price {
    color: #D400D9 !important;
}
.price {
    font-size: 82px;
    color: #D400D9 !important;
}
.whatsapp img {
    margin-left: 10px;
}
.video {
    width: 100%;
    max-width: 1200px;
    border-radius: 10px;
    position: relative;
}
@keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
}

.input-place {
    color: #ffffff;
    font-size: 12px;
    font-weight: 400;
}
.contact-input {
    background-color: #2A0865;
    border-radius: 10px;
    z-index: 4;
    position: relative;
    color: #ffffff;
    padding-left: 10px;
    height: 50px !important;
    border: none;
    height: 100%;
}
.contact-input-textarea {
    background-color: #2A0865;
    border-radius: 10px;
    z-index: 4;
    position: relative;
    color: #ffffff;
    padding-left: 10px;
    border: none;
    height: 100%;
}
.select-input {
    height: 100%;
    background-color: #2A0865;
    color: #ffffff;
    border: none;
    border-radius: 10px;
    padding-left: 10px;
    height: 50px !important;
    position: relative;
    z-index: 4;
}
.select-input:focus {
    outline: none;
    border-bottom: 2px solid#D400D9;
}
textarea:focus {
    outline: none;
    border-bottom: 2px solid#D400D9;
}
input:focus {
    outline: none;
    border-bottom: 2px solid#D400D9;
}
.input::placeholder {
    color: #4307AA;
    font-size: 12px;
    padding-left: 10px;
    font-weight: 400;
}
form {
    display: flex;
    align-items: center;
    flex-direction: column;
}
.gost-section {
    padding-top: 100px;
    gap: 20px 0px;
    flex-direction: column;
    justify-content: center;
}
.fun-area p{
    text-align: start;
}
.fun-area h1 {
    text-align: start;
}
.fun-area h2 {
    text-align: start;
}
.gost-section h3{
    color: #D400D9;
    font-size: 22px;
    font-weight: 400px !important;
}
.gost-section span {
    padding: 5px 20px;
    background-color: #D400D9;
    color: #ffffff;
    border-radius: 30px;
    margin-bottom: 0px;
    font-size: 12px;
    align-items: center;
    display: flex;
    justify-content: center;
}
.gost-section h2 {
    font-size: 38px;
    color: #ffffff;
    font-weight: 600;
    font-family: 'Chakra Petch';
}
.gost-section p {
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
}
.download-button {
    border: 1px solid #D400D9;
    border-radius: 10px;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 0px 20px;
    background: transparent;
    color: #ffffff;
    font-size: 20px;
    font-weight: 700;
}
.download-button:hover {
    cursor: pointer;
    text-decoration: none;
}
.swiper-img {
    width: 250px;
    object-fit: cover;
}
.games-title {
    color: #ffffff;
    font-size: 42px;
    font-weight: 600;
    font-family: 'Chakra Petch';
}
.mySwiper {
    margin-bottom: 30px;
}
.map-title {
    color: #2A0865;
    font-size: 20px;
}
.map-text {
    font-size: 16px;
    color: #c3c3c3;
}


